import React, { Component, Fragment } from 'react';

class HappyNewYear extends Component {
    render() {
        return (
            <Fragment>
                <div className='img-happy-new-year'>
                    <div class='context-happy' style={{color:'red',"font-weight": "900"}}>
                        Chúc Pé iu và gia đình năm mới vui vẻ,
                    </div>
                    <div class='context-happy' style={{color:'red',"font-weight": "900"}}>
                        mạnh khỏe và  hạnh phúc.
                    </div>
                    <div class='context-happy' style={{color:'#f30fac',"font-weight": "900"}}>
                        Mong mãi mãi được ở bên Pé iu
                    </div>
                    <div class='context-happy' style={{color:'#f30fac',"font-weight": "900"}}>
                        Thương Pé iu nhiều nè.
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default HappyNewYear