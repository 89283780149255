import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  useEffect(() => {
    if (!timeLeft.days && !timeLeft.hours && !timeLeft.minutes && !timeLeft.seconds) {
      navigate('/Pe-iu');
    }
  }, [timeLeft, navigate]);

  return (
    <Fragment>
      <div className='main-context'  style={{"color": "red" ,"font-weight": "900"}}>
        Hello Pé iu sắp đến tết rồi nè. Chỉ còn:
      </div>
      <div>
        {Object.keys(timeLeft).length ? (
          Object.entries(timeLeft).map(([unit, value]) => (
            <div key={unit}>
              {value} {unit}{" "}
            </div>
          ))
        ) : (
          <span>Time's up!</span>
        )}
      </div>
      <div style={{"color": "red" ,"font-weight": "900"}}>
        Là đến tết rồi.
      </div>
    </Fragment>

  );
};

export default CountdownTimer;
