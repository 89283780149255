import React, { Component, Fragment } from 'react';
import './Home.css'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
import CountdownTimer from '../utils/CountdownTimer';
import HappyNewYear from './HappyNewYear';
class Home extends Component {
    render() {
        return (
            <Fragment>
                <div className='container'>
                    <div className='context'>
                        <div className='countdown'>
                            <Router>
                                <Routes>
                                    <Route path="/" element={<CountdownTimer targetDate="2024-02-10T00:00:00" />} />
                                    <Route path="/Pe-iu" element={<HappyNewYear />}/>
                                    <Route path="*" element={<Navigate to="/" />} />
                                </Routes>
                            </Router>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Home